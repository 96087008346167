<template>
  <div class="document-templates">
    <div class="page_header">
      <h1>Шаблоны документов</h1>
      <el-button type="primary" size="mini" @click="create" icon="el-icon-plus">Добавить</el-button>
    </div>
    <div class="page_content" v-loading="loading">
      <div class="list" v-if="documentTemplates">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>Название шаблона</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(template, idx) in documentTemplates" :key="template.id">
            <td>{{ ++idx }}</td>
            <td>{{ template.title }}</td>
            <td>
              <el-tag size="mini" v-if="template.for_calculations">Для расчетов</el-tag>
              <el-tag size="mini" v-if="template.for_orders">Для заказов</el-tag>
              <el-tag size="mini" v-if="template.for_acts">Акт</el-tag>
            </td>
            <td>
              <el-link
                  :href="'https://online.lbsteklo.ru/docs/templates/'+template.file"
                  type="info"
                  target="_blank" v-if="template.file">Скачать
              </el-link>
            </td>
            <td>
              <el-button
                  type="primary"
                  size="mini"
                  @click="openDrawer(template)"
                  icon="el-icon-edit"
              >Изменить
              </el-button>
              <el-popconfirm
                  @confirm="remove(template.id)"
                  confirm-button-text='Да'
                  cancel-button-text='Нет'
                  title="Вы действительно хотите удалить?">
                <el-button
                    slot="reference"
                    icon="el-icon-delete"
                    size="mini" type="danger"
                    style="margin-left: 5px;"></el-button>
              </el-popconfirm>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <br>
      <el-divider></el-divider>
      <p>Перечень ключевых слов, доступных в шаблонах (записываются в документе word в формате ${key} - без
        пробелов.):</p>
      <table class="table info-table">
        <thead>
        <tr>
          <th>Ключевое слово, ${key}</th>
          <th>Что подставится</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>${org.title.short}</td>
          <td>Короткое наименование организации</td>
        </tr>
        <tr>
          <td>${org.title.full}</td>
          <td>Полное наименование организации</td>
        </tr>
        <tr>
          <td>${org.inn}</td>
          <td>ИНН организации</td>
        </tr>
        <tr>
          <td>${org.kpp}</td>
          <td>КПП организации (при наличии)</td>
        </tr>
        <tr>
          <td>${org.ogrn}</td>
          <td>ОГРН организации (при наличии)</td>
        </tr>
        <tr>
          <td>${org.address.legal}</td>
          <td>Юридический адрес организации</td>
        </tr>
        <tr>
          <td>${org.address.fact}</td>
          <td>Фактический адрес организации</td>
        </tr>
        <tr>
          <td>${org.contacts.phones}</td>
          <td>Телефоны организации</td>
        </tr>
        <tr>
          <td>${org.contacts.emails}</td>
          <td>Email-ы организации</td>
        </tr>
        <tr>
          <td>${doc.number}</td>
          <td>Номер документа</td>
        </tr>
        <tr>
          <td>${doc.date}</td>
          <td>Дата документа</td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        <tr>
          <td>${}</td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>

    <el-drawer
        :visible.sync="drawerActive"
        direction="rtl"
        size="30%"
        custom-class="drawer_box"
        :before-close="closeDrawer"
        :destroy-on-close="true"
        :show-close="false"
        :wrapperClosable="false"
        :withHeader="false"
        :append-to-body="true"
        class="settings_drawer"
    >
      <div class="drawer_header">
        <h3 class="title">Шаблон документа</h3>
        <button class="close_btn" @click="closeDrawer">
          <i class="el-icon-close"></i>
        </button>
      </div>

      <div class="drawer_content" v-if="documentForm" v-loading="loading">
        <el-form :model="documentForm" :rules="documentFormRules" ref="documentForm" class="form"
                 @submit.native.prevent="documentFormAction('documentForm')">
          <el-form-item label="Наименование шаблона" prop="title">
            <el-input v-model="documentForm.title" size="mini"></el-input>
          </el-form-item>

          <el-form-item label="Для расчетов" prop="for_calculations">
            <el-switch
                v-model="documentForm.for_calculations"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </el-form-item>

          <el-form-item label="Для заказов" prop="for_orders">
            <el-switch
                v-model="documentForm.for_orders"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </el-form-item>

          <el-form-item label="Если нужен акт" prop="for_acts">
            <el-switch
                v-model="documentForm.for_acts"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </el-form-item>

          <el-form-item label="Файл шаблона">
            <el-upload
                class="upload-demo"
                drag
                action="https://online.lbsteklo.ru/v21/documents/upload"
                :show-file-list="false"
                :headers="{Authorization: getLocalToken()}"
                :on-success="successFileUpload"
                :on-progress="progressFileUpload"
                :before-upload="beforeFileUpload">
              <i :class="uploadIcon"></i>
              <div class="el-upload__text">Перетащите файл шаблона сюда <br>или <em>нажмите для выбора</em></div>
              <div class="el-upload__tip" slot="tip">Шаблон в формате .docx (Word)</div>
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-button
                type="primary"
                size="mini"
                @click="documentFormAction('documentForm')">Сохранить
            </el-button>
            <el-button size="mini" @click="closeDrawer">Отменить</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as documentActions} from "@/store/modules/documents/templates";

import {getItem} from "@/helpers/persistanceStorage";

export default {
  name: "DocumentTemplates",
  data() {
    return {
      documentForm: null,
      drawerActive: false,
      uploadIcon: 'el-icon-upload',
      documentFormRules: {
        title: [
          {required: true, message: 'Наименование шаблона', trigger: 'blur'}
        ]
      }
    }
  },
  computed: {
    ...mapState({
      loading: state => state.document_templates.loading,
      submitting: state => state.document_templates.submitting,
      documentTemplates: state => state.document_templates.templates,
      errors: state => state.document_templates.errors
    })
  },
  methods: {
    create() {
      this.drawerActive = true
      this.documentForm = {
        title: '',
        for_orders: false,
        for_calculations: false,
        for_acts: false,
        file: '',
      }
    },
    openDrawer(data) {
      this.drawerActive = true
      this.documentForm = _.cloneDeep(data)
    },
    closeDrawer() {
      this.drawerActive = false
      this.documentForm = null
      this.uploadIcon = 'el-icon-upload'
    },
    documentFormAction(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch(documentActions.saveTemplate, this.documentForm).then(() => {
            this.loadDocumentTemplates()
            this.drawerActive = false
            this.documentForm = null
            this.uploadIcon = 'el-icon-upload'
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    getLocalToken() {
      const token = getItem('accessToken')
      return token ? `Token ${token}` : ''
    },
    progressFileUpload() {
      this.uploadIcon = 'el-icon-upload'
    },
    beforeFileUpload() {
      this.uploadIcon = 'el-icon-loading'
    },
    successFileUpload(file) {
      console.log(file)
      this.uploadIcon = 'el-icon-check'
      this.documentForm.file = file.file
      this.documentForm.title = file.title
    },
    remove(id) {
      this.$store.dispatch(documentActions.removeTemplate, id).then(() => {
        this.loadDocumentTemplates()
      })
    },
    loadDocumentTemplates() {
      this.$store.dispatch(documentActions.getTemplates)
    }
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
